import Vue from "vue";

import splitPane from '../../standard/split-pane/index';


export default Vue.extend({
    components: {
        splitPane
    },
    data: () => ({
        show: true,
        loading: false
    }),
    methods: {
        async query() {

        }
    }
});
